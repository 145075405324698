<template>
  <main
    class="min-height-100vh is-flex is-flex-direction-column is-align-items-center bg-white"
  >
    <section class="is-flex-grow-1 full-width max-width-widescreen pb-6 px-5">
      <Navbar></Navbar>
      <router-view />
    </section>
    <TheFooter></TheFooter>
  </main>
</template>

<script>
import Navbar from "@/components/NavBar.vue";
import TheFooter from "@/components/TheFooter.vue";
export default {
  name: "HomeView",
  components: {
    Navbar,
    TheFooter,
  },
  created() {
    document.title = "Burning Heart Frankfurt";
  },
};
</script>
